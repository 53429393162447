import * as React from 'react';
import { graphql } from 'gatsby';
import '../../../pages/styles.scss';
import {MarkdownRemark} from '../../../models/markdown-remark';
import {BasePageData, BasePageFooter, BasePageHeader} from '../../../helpers/base-page';
import {TitleSection} from './title-section';
import {ArticleCard} from '../article-card';
import readingTime from 'reading-time';
import AvatarPlaceholder from '../../../images/man.svg';
import styled from 'styled-components';
import {device} from '../../../models/devices';

interface AuthorPageData extends BasePageData {
  author: MarkdownRemark<{
    image: string;
    firstName: string;
    lastName: string;
    description: string;
    jobTitle: string;
    twitter?: string;
    linkedin?: string;
    facebook?: string;
  }>;
  articles: MarkdownRemark<{
    title: string;
    urlFriendlyName: string;
    category: string;
    author: string;
    image: string;
    Body: string;
  }>;
  categories: MarkdownRemark<{
    name: string;
    urlFriendlyName: string;
  }>;
}

const ArticlesList = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 39px;
  column-gap: 31px;
  max-width: 1170px;
  margin: -80px auto 50px;

  @media screen and ${device.tablet} {
    grid-template-columns: 1fr;
    margin: -40px 24px 40px;
  }
`;

const AuthorPage = ({data}: {data: AuthorPageData}): JSX.Element => {

  const author = data.author.edges[0].node.frontmatter;

  return (
    <React.Fragment>
      <BasePageHeader
        siteTitle={data.metaInfo.edges[0].node.frontmatter.siteTitle}
        siteDescription={data.metaInfo.edges[0].node.frontmatter.siteDescription}
        siteDescriptionTitle={data.metaInfo.edges[0].node.frontmatter.siteDescriptionTitle}
        siteImage={data.metaInfo.edges[0].node.frontmatter.siteImage}
        siteUrl={data.metaInfo.edges[0].node.frontmatter.siteUrl}
        logoLight={data.logos.edges[0].node.frontmatter.logoHeaderLight}
        logoDark={data.logos.edges[0].node.frontmatter.logoHeaderDark}
        logoShort={data.logos.edges[0].node.frontmatter.logoShort}
        links={data.navigation.edges[0].node.frontmatter.topCenterNavigation}
        modules={data.modules.edges.map(m => m.node.frontmatter)}
        usecases={data.usecases.edges.map(u => u.node.frontmatter)}
        hubspotFormId={data.hubspot.edges[0].node.frontmatter.hubSpotFormId}
        hubspotPortalId={data.hubspot.edges[0].node.frontmatter.hubSpotPortalId}
        contactFormConfig={data.contactFormConfig.edges[0].node.frontmatter}
        contentType={'blog-post'}
      />
      <TitleSection
        avatar={author.image?.length ? author.image : AvatarPlaceholder}
        firstName={author.firstName}
        lastName={author.lastName}
        description={author.description}
        job={author.jobTitle}
        twitter={author.twitter}
        linkedin={author.linkedin}
        facebook={author.facebook}
      />
      <ArticlesList>
        {data.articles.edges.map(i => i.node.frontmatter).map((article, index) => {
          const category = data.categories.edges
            .map(i => i.node.frontmatter)
            .find(category => category.name === article.category);
          return (
            <ArticleCard
              key={index}
              img={article.image}
              categoryName={article.category}
              categoryUrl={category?.urlFriendlyName ?? ''}
              title={article.title}
              urlFriendlyName={article.urlFriendlyName}
              authorAvatar={author.image}
              authorFirstName={author.firstName}
              authorLastName={author.lastName}
              readingTime={readingTime(article.Body).text}
            />
          );
        })}
      </ArticlesList>
      <BasePageFooter
        feedbackTitle={data.contact.edges[0].node.frontmatter.title}
        feedbackLabel={data.contact.edges[0].node.frontmatter.subText}
        feedbackButtonText={data.contact.edges[0].node.frontmatter.buttonText}
        feedbackInputPlaceholder={data.contact.edges[0].node.frontmatter.inputPlaceholder}
        navigation={data.navigation}
        integrations={data.integrations}
        blogArticles={data.blogArticles}
        blogCategories={data.blogCategories}
        features={data.features.edges.map(f => f.node.frontmatter)}
        footerLogo={data.logos.edges[0].node.frontmatter.logoFooter}
      />
    </React.Fragment>
  );
};

export default AuthorPage;

export const pageQuery = graphql`
  query($firstName: String!, $lastName: String!, $url: String!) {
    ...BaseData
    author: allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "/^(?!.*/site/).*authors/"},
        frontmatter: {
          firstName: {eq: $firstName}
          lastName: {eq: $lastName}
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            image
            firstName
            lastName
            description
            jobTitle
            twitter
            linkedin
            facebook
          }
        }
      }
    }
    articles: allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "/^(?!.*/site/).*blogs/"},
        frontmatter: {author: {eq: $url}}
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            urlFriendlyName
            category
            author
            image
            Body
          }
        }
      }
    }
    categories: allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "/^(?!.*/site/).*categories/"},
      }
    ) {
      edges {
        node {
          frontmatter {
            name
            urlFriendlyName
          }
        }
      }
    }
  }
`;
