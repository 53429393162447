import React from 'react';
import Background from '../../../images/impact-background.png';
import Twitter from '../../../images/twitter.svg';
import Facebook from '../../../images/facebook.svg';
import LinkedIn from '../../../images/linkedin.svg';
import styled from 'styled-components';
import {device} from '../../../models/devices';

const Container = styled.section`
  background: url(${Background}) no-repeat top center;
  background-size: 100% 100%;
  padding-bottom: 152px;
  padding-top: 184px;

  @media screen and ${device.tablet} {
    background-size: auto 748px;
    padding: 115px 24px 80px;
  }
`;

const Wrapper = styled.div`
  max-width: 1170px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: repeat(3, auto);
  grid-template-areas:
    "avatar name"
    "avatar social"
    "avatar description";
  grid-column-gap: 36px;

  @media screen and ${device.tablet} {
    grid-template-columns: 1fr;
    grid-template-areas:
      "avatar"
      "name"
      "social"
      "description";
  }
`;

const Image = styled.img`
  border-radius: 50%;
  border: 10px solid white;
  width: 180px;
  height: 180px;
  grid-area: avatar;

  @media screen and ${device.tablet} {
    margin: 0 auto 20px;
  }
`;

const Name = styled.span`
  grid-area: name;
  color: white;
  font-weight: 500;
  font-size: 55px;
  line-height: 1.47;

  @media screen and ${device.tablet} {
    font-size: 32px;
    line-height: 1.5em;
    text-align: center;
  }
`;

const JobTitle = styled.span`
  color: #cfe7fc;
  font-weight: 500;
  font-size: 13px;
  line-height: 1.78;
`;

const Description = styled.div`
  grid-area: description;
  color: #cfe7fc;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.78;
`;

const SocialLink = styled.a`
  margin-left: 20px;
  filter: brightness(1.2);
`;

export const TitleSection = (props: {
  avatar: string;
  firstName: string;
  lastName: string;
  job: string;
  description: string;
  twitter?: string;
  linkedin?: string;
  facebook?: string;
}): JSX.Element => {
  return (
    <Container>
      <Wrapper>
        <Image src={props.avatar}/>
        <Name>{props.firstName} {props.lastName}</Name>
        <div style={{marginTop: 20, marginBottom: 28, gridArea: 'social'}}>
          <JobTitle>{props.job}</JobTitle>
          {props.twitter && (
            <SocialLink href={props.twitter}><img src={Twitter} alt="twitter"/></SocialLink>
          )}
          {props.facebook && (
            <SocialLink href={props.facebook}><img src={Facebook} alt="facebook"/></SocialLink>
          )}
          {props.linkedin && (
            <SocialLink href={props.linkedin}><img src={LinkedIn} alt="linkedin"/></SocialLink>
          )}
        </div>
        <Description>{props.description}</Description>
      </Wrapper>
    </Container>
  );
};
